<template>
  <div>
    <div class="container">
      <div class="float_right">
        <p class="img"><a @click="()=>{
          contentHtml = detail.rule
          contentHtmlModal = true
        }"><img :src="oss+'pay_video_icon_2.png'"/></a></p>
        <p class="img"><a @click="goService(detail.kefu_url)"><img :src="oss+'pay_video_icon_1.png'"/></a></p>
        <p class="img"><router-link to="/wap/video/selfRefund" v-if="is_open_refund"><img :src="oss+'pay_video_icon_3.png'"/></router-link></p>
      </div>

      <div class="header">
        <div class="djs">
          <van-count-down millisecond :time="time">
            <template #default="timeData">
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds }}</span>
              <span class="colon">:</span>
              <span class="block">{{moment(timeData.milliseconds).format("SS")}}</span>
            </template>
          </van-count-down>
        </div>

        <div class="video"><img :src="oss+'video_img.png'" width="100%"/></div>
      </div>

      <div class="info">
        <div class="price">
          <label>¥<em>{{detail.money | keepTwoNum}}</em></label>
          <van-tag round type="primary" color="#eb333b" text-color="#fff" size="medium">解锁后续剧情+抽手机</van-tag>
        </div>
        <div class="tips">短剧用户专属福利</div>
        <div class="title">{{detail.title}}</div>
      </div>

      <div class="parameter">
        <div class="item">
          <div class="icon"><img :src="oss+'pay_video_icon_10.png'"/></div>
          <div class="t1">超大屏</div>
          <div class="t2">6.1英寸</div>
        </div>
        <div class="item">
          <div class="icon"><img :src="oss+'pay_video_icon_11.png'"/></div>
          <div class="t1">无需合约版</div>
          <div class="t2">无需合约版</div>
        </div>
        <div class="item">
          <div class="icon"><img :src="oss+'pay_video_icon_12.png'"/></div>
          <div class="t1">128GB 256GB</div>
          <div class="t2">128GB 256GB</div>
        </div>
        <div class="item">
          <div class="icon"><img :src="oss+'pay_video_icon_13.png'"/></div>
          <div class="t1">普通厚</div>
          <div class="t2">7.85m</div>
        </div>
      </div>

      <div class="paybtn"><van-button round block @click="onsubmit">确认领取</van-button></div>

      <div class="payList" v-if="payList.length">
        <van-radio-group v-model="json.payWay">
          <van-cell-group :border="false">
            <van-cell v-for="item in payList" :key="item.key">
              <template #icon><img :src="item.img" height="20"/></template>
              <template #title>
                {{item.name}}
              </template>
              <template #right-icon>
                <van-radio :name="item.payWay" checked-color="#07c160" icon-size="18px"></van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>

      <div class="foot">
        <p>{{siteConfig.name}}</p>
        <p>{{siteConfig.icp}}</p>
        <p>{{siteConfig.address}}</p>
        <p><a :href="'tel:'+siteConfig.phone">{{siteConfig.phone}}</a></p>
      </div>

    </div>


    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#ff3800" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>


    <van-popup v-model="paySuccessModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" class="PayStatus">
      <div class="success" v-if="paysuccess==1">
        <div><van-icon name="checked" size="100" color="#07c160"/></div>
        <div class="h4">支付成功</div>
        <div class="mt10 count-down">
          <van-count-down :time="paytime" @finish="finishPay">
            <template #default="timeData">
              <span class="block">{{ timeData.seconds }}</span>
              <span>S 后跳转</span>
            </template>
          </van-count-down>
        </div>
      </div>
      <div class="fail" v-if="paysuccess==0">
        <div class="h4">恭喜您</div>
        <div class="h5">获得<span>限时降价</span>资格</div>
        <div class="price"><label>¥<em>{{detail.sec_money | keepTwoNum}}</em></label></div>
        <div class="mt20"><van-button round type="primary" color="#428bf4" block @click="onSubmitPay(0)">立即抢购</van-button></div>
        <div class="mt20"><van-button round type="primary" color="#ff0000" block @click="onSubmitPay(1)">我已付款</van-button></div>
      </div>
    </van-popup>

  </div>

</template>
<script>
  import moment from 'moment'
  import {keepTwoNum,getordernum} from '@/utils/utils'
  import {initCloud} from '@wxcloud/cloud-sdk'

  import {GetPayConfig,CreateOrder, GetOrderStatus} from './services/services'
  export default {
    name:'videoPage',
    components: {},
    data () {
      return {
        contentHtml:'',
        time:1000*60*5,
        detail:{},
        payList:[],
        json:{
          money:'',
          type:'',
          payWay:0,
          order_sn:'',
          payAccount:'',
          url:location.href
        },
        paySuccessModal:false,
        create_status:0,
        paysuccess:0,
        paytime:3 * 1000,
        count:0,
        maxcount:10,
        timer:null,
        contentHtmlModal:false,
        type:'',
        is_open_refund:false,
        siteConfig:{}
      }
    },
    created(){
      // const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query
      }
      this.type = this.json.type==undefined ? undefined:this.json.type
      if(this.json.order_sn != ''){
        setTimeout(()=>{
          this.findOrderStatus()
        },1000)
      }
    },
    filters:{
      keepTwoNum
    },
    computed:{
    },
    mounted(){
      // if(sessionStorage.getItem('order_info')){
      //   let order_info = JSON.parse(sessionStorage.getItem('order_info'))
      //   this.json={
      //     ...this.json,
      //     ...order_info
      //   }
      // }

      this.findpayConfig()
    },
    methods:{
      moment,
      initCloud,
      getOpenId(){
        const redirect_url = location.href
        location.href = 'https://pay2.senhuo.cn/pay/WechatOpenId.php?redirect_url=' + encodeURIComponent(redirect_url)
      },
      goService(url){
        location.href=url
      },
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            const {payConfig,companyInfo} = result.data.data
            this.detail = result.data.data
            this.siteConfig = companyInfo

            this.payList=payConfig
            this.json = {
              ...this.json,
              payWay:payConfig[0].payWay,
              payAccount:payConfig[0].payAccount,
              money:this.detail.money
            }
            this.is_open_refund = this.detail.is_open_refund

          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      onsubmit(){
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })
        let pay=this.payList.find(item=>item.payWay == this.json.payWay)
        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        this.getCreateOrder({
          ...this.json,
          payAccount:pay.payAccount,
          order_sn:order_sn
        })
      },
      getCreateOrder(res){
        CreateOrder(res).then(result => {
          if(result.data.code==1){
            // sessionStorage.setItem('order_info',JSON.stringify(res))
            const {data} = result.data
            let payWay = res.payWay
            if(payWay==14){
              location.href=data
              // this.payWeChat(data)
            }
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      async payWeChat(orderId){
        let c1 = new this.initCloud().Cloud({
          // 必填，表示是未登录模式
          identityless: true,
          // 资源方 AppID
          resourceAppid: 'wxdada9bc45300a359',
          // 资源方环境 ID
          resourceEnv: 'cloud1-3gua9rkm875bd364',
        })

        await c1.init()
        const res =  await c1.callFunction({
          name: 'public',
          data: {
            action: 'getUrlScheme',
            path:'pages/index/index', // 想跳转的小程序路径
            orderId:orderId, // 预下单单号
          },
        }).then((res)=>{
          let result = res.result
          if(result.errCode==0){
            location.href = result.openlink
          }
        })
      },
      findOrderStatus(){
        this.paySuccessModal=true
        GetOrderStatus({order_sn:this.json.order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = 1
            this.clearTime()

          }else{
            this.paysuccess = 0
            if(this.timer == null && this.count<this.maxcount){
              this.getTimerStatus()
            }
          }
        })
      },
      getTimerStatus(){
        this.timer = setInterval(() => {
          if(this.count>=this.maxcount){
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus()
        },1*1000)
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem('order_info')
      },
      finishPay(){
        this.$router.push({path:'/wap/video/success',query:{wechat_url:escape(this.detail.wechat_service_url)}})
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            this.findOrderStatus()
            break;
          case 0:
            this.json.money = this.detail.sec_money
            this.getCreateOrder(this.json)
            break;
        }
      }
    }
  }

</script>
<style lang="less" scoped>
  .container{background:#fff;
    .float_right{top:20px;
      p{padding:3px 0px;}
    }
    .header{background:#ff5708 url('@{static-dir}pay_video_header.png') no-repeat;background-size:100% auto;height:auto;padding:86px 0px 10px;
      .djs{width:64%;text-align:right;margin:auto;
        .van-count-down{color:#fff;font-size:20px;}

      }
      .video{margin:22px 10px 0px;}
    }
    .info{padding:0px 10px;margin-top:4px;
      .price{color:#eb5b27;display:flex;align-items:center;
        label{display:inline-block;}
        em{font-size:30px;font-weight:700;font-family:Arial;}
        span{height:20px;padding:0px 10px;}
      }
      .tips{color:#999;font-size:12px;}
      .title{margin-top:4px;font-size:16px;font-weight:700;}
    }
    .parameter{display:flex;margin-top:4px;
      .item{width:25%;text-align:center;
        .icon{
          img{width:26px;}
        }
        .t1,.t2{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;height:16px;line-height:16px;transform:scale(0.9);}
        .t1{}
        .t2{color:#999;font-size:12px;}
      }
    }
    .paybtn{margin:10px auto 0px;height:66px;
      .van-button{width:80%;background:linear-gradient(0deg, rgb(247, 26, 30), rgb(255, 102, 48));height:58px;line-height:58px;font-size:26px;color:#fff;animation:2s ease-in-out 0s infinite normal none running scaleDraw;margin:auto;}
    }
    .payList{}

  }
  .foot{color:#999;padding-bottom:20px;text-align:center;font-size:12px;margin-top:5px;
    p{padding:2px 0px;}
  }
  .PayStatus{width:88%;border-radius:6px;
    .success{padding:30px;text-align:center;
      .h4{font-size:20px;font-weight:700;margin-top:10px;}
      .count-down{
        span{color:#999;}
      }
    }
    .fail{padding:20px 20px 30px 20px;text-align:center;
      .h4{font-size:24px;font-weight:700;}
      .h5{margin-top:10px;font-size:16px;color:#666;
        span{color:#ed5b42;}
      }
      .price{color:#ed5b42;margin-top:10px;
        em{font-size:40px;}
      }
      .old_price{font-size:12px;color:#999;text-decoration:line-through;}
    }
  }
  @keyframes scaleDraw{
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.2);
    }
  }
</style>
